
import { loadSvg } from '@/core/utils';
// expand mobile nav accordion

document.querySelectorAll('.js-mobile-menu-expand').forEach((button) => {
    button.addEventListener('click', () => {
        button.classList.toggle('active');
        const items = button.nextElementSibling;

        items.style.height = button.classList.contains('active')
            ? `${items.scrollHeight}px`
            : 0;
    });
});

document.addEventListener('DOMContentLoaded', () => {
    loadSvg(fxvmApp.svgSpriteUrl);
});
