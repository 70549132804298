// import code for every page

import '@/global';

// import code by section presence
// section should have data-js-import="name" attribute

const sections = [
    'header-navigation',
    'faq',
    'testimonials',
    'team-carousel',
    'what-vps-calc',
    'scrolling-ticker',
    'pricing-slider',
    'odometer',
    'scroll-to-section',
    'contact-form',
    'broker-selector-section',
    'globe-section',
    'video-section',
    'affiliates-profit-range',
    'exit-pop-up',
    'benefits-forex-trader-section'
];

sections
    .filter((name) => document.querySelector(`section[data-js-import="${name}"]`))
    .forEach((name) => {
        import(
            // /* webpackChunkName: "forexvpsChunk" */
            `@/sections/${name}`
            );
    });

