export function detectTouchScreen() {
  let hasTouchScreen = false;

  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  }

  return hasTouchScreen;
}

export function vimeoPlaceholderClickHandler(event) {
  const target = event.currentTarget;

  target.removeEventListener('click', vimeoPlaceholderClickHandler);
  delete target.dataset.placeholder;

  const url = `https://player.vimeo.com/video/${target.dataset.vimeoSrc}`;
  const muted = detectTouchScreen() ? '&muted=1' : '';

  target.innerHTML = `<iframe class="js-success-video-object" src="${url}?autoplay=1${muted}" allow="autoplay; fullscreen" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
}

export function loadSvg(url, container = null) {
  const req = new XMLHttpRequest();

  req.onload = function (e) {
    let svg = document.implementation.createHTMLDocument('');

    svg.body.innerHTML = e.target.response;

    if (!container) {
      container = document.createElement('div');

      container.style.position = 'absolute';
      container.style.width = 0;
      container.style.height = 0;
      container.style.overflow = 'hidden';
      container.style.zIndex = -9999;

      document.body.appendChild(container);
    }

    container.appendChild(svg.body.firstElementChild);

    svg = null;
  };

  req.open('GET', url, true);
  req.responseType = 'text';
  req.send();
}
